import React from 'react';
import { ApplicationStatus } from '../../../types/types';

export type ApplicationContext = {} & ApplicationProps;

export type ApplicationProps = {
  userTotalStoreCreditNumber?: number;
  userTotalStoreCredit?: string;
  redeemableStoreCredit?: string;
  status: ApplicationStatus;
  updateCheckout: () => Promise<void>;
  isWalletEmpty: boolean;
  appData: {
    appId: string;
    appName: string;
  };
  shouldRenderWidget: boolean;
  giftCardError?: string;
};

export type AppData = {
  appId: string;
  appName: string;
};

const ApplicationComponentContext = React.createContext<ApplicationProps>(
  {} as ApplicationProps,
);
export const ApplicationContextProvider = ApplicationComponentContext.Provider;

export const useApplicationContext = () =>
  React.useContext(ApplicationComponentContext);
