import React, { FC, useEffect, useState } from 'react';
import { StoreCreditBalance } from '../StoreCreditBalance/StoreCreditBalance';
import { AmountInput } from '../AmountInput/AmountInput';
import { useApplicationContext } from '../../core/hooks/useApplicationContext/useApplicationContext';
import { ApplicationStatus } from '../../types/types';
import { useLoadedBiEvent } from '../../core/hooks/useLoadedBiEvent';

export enum AppDataHooks {
  AppContainer = 'AppDataHooks.AppContainer',
  AppLoader = 'AppDataHooks.AppLoader',
}

type AppProps = {};

const App: FC<AppProps> = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { status, shouldRenderWidget, userTotalStoreCreditNumber } =
    useApplicationContext();
  const { reportLoadedBiEvent } = useLoadedBiEvent();

  useEffect(() => {
    userTotalStoreCreditNumber && reportLoadedBiEvent();
  }, [reportLoadedBiEvent, userTotalStoreCreditNumber]);

  if (shouldRenderWidget === false) {
    return null;
  }

  if (status === ApplicationStatus.INITIALIZING) {
    return <div data-hook={AppDataHooks.AppLoader}></div>;
  }

  return (
    <div data-hook={AppDataHooks.AppContainer}>
      <StoreCreditBalance
        setIsCollapsed={() => setIsCollapsed(!isCollapsed)}
        isCollapsed={isCollapsed}
      />
      {!isCollapsed && <AmountInput />}
    </div>
  );
};

export default App;
