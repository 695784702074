import React, { FC } from 'react';
import '@wix/design-system/styles.global.css';
import { LocaleKeysProvider } from '../../../../locale-keys/LocaleKeys';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  ApplicationContextProvider,
  ApplicationProps,
} from '../../core/hooks/useApplicationContext/useApplicationContext';

const AppWrapper: FC<{
  Component: React.FC;
  applicationProps: ApplicationProps;
}> = ({ Component, applicationProps }) => {
  const { t } = useTranslation();

  return (
    <ApplicationContextProvider value={applicationProps}>
      <LocaleKeysProvider translateFn={t}>
        <Component />
      </LocaleKeysProvider>
    </ApplicationContextProvider>
  );
};

export default AppWrapper;
