/* eslint-disable */
/* tslint:disable */
import React from 'react';

export function LocaleKeys<R extends string>(t: (...args: unknown[]) => R) {
  return {
    app: {
      widget: {
        welcome: () => t('app.widget.welcome'), /* Hello */
      },
      settings: {
        label: () => t('app.settings.label'), /* Configure Widget */
        tabs: {
          main: () => t('app.settings.tabs.main'), /* Main */
          design: () => t('app.settings.tabs.design'), /* Design */
        },
        defaults: {
          greetingsPretext: () => t('app.settings.defaults.greetingsPretext'), /* to */
        },
      },
    },
    checkoutSlot: {
      redeemStoreCredit: {
        codeAlreadyInUse: {
          errorMessage: () => t('checkoutSlot.redeemStoreCredit.codeAlreadyInUse.errorMessage'), /* This code is already in use. */
        },
        cta: () => t('checkoutSlot.redeemStoreCredit.cta'), /* Apply */
        upToOrderTotal: {
          warningMessage: () => t('checkoutSlot.redeemStoreCredit.upToOrderTotal.warningMessage'), /* You can only use up to the total amount of your order. */
        },
        creditEmpty: {
          errorMessage: () => t('checkoutSlot.redeemStoreCredit.creditEmpty.errorMessage'), /* Your store credit is empty. */
        },
        title: {
          $value: () => t('checkoutSlot.redeemStoreCredit.title'), /* You have {amount} in store credit */
          new: (data: Record<'amount', unknown>) => t('checkoutSlot.redeemStoreCredit.title.new', data), /* You have {{amount}} in store credit */
        },
      },
      paymentDetails: {
        enterStoreCredit: {
          error: {
            general: () => t('checkoutSlot.paymentDetails.enterStoreCredit.error.general'), /* We’re experiencing a technical issue on our end. Refresh the page and try again. */
            serviceDown: () => t('checkoutSlot.paymentDetails.enterStoreCredit.error.serviceDown'), /* Sorry, we can't accept store credit right now because of a technical issue. Please continue without store credit or try again later. */
            disabled: () => t('checkoutSlot.paymentDetails.enterStoreCredit.error.disabled'), /* Your store credit has been disabled. Please contact us for more details.  */
            noBalance: () => t('checkoutSlot.paymentDetails.enterStoreCredit.error.noBalance'), /* You don't have any remaining store credit. */
            wrongCurrency: () => t('checkoutSlot.paymentDetails.enterStoreCredit.error.wrongCurrency'), /* The store credit currency doesn't match the currency of the site. */
            expired: () => t('checkoutSlot.paymentDetails.enterStoreCredit.error.expired'), /* The store credit has expired. */
          },
        },
      },
    },
  };
}

export type ILocaleKeys = ReturnType<typeof LocaleKeys>;

const LocaleKeysContext = React.createContext({} as ILocaleKeys);
export const LocaleKeysProvider: React.FC<{ translateFn?: (...args: unknown[]) => string; localeKeys?: ILocaleKeys; children?: React.ReactNode }> = ({ translateFn, localeKeys, children }) => {
    if (!translateFn && !localeKeys) { throw new Error('Either translateFn or localeKeys must be provided') }
    const value = (typeof translateFn === 'function' ? LocaleKeys(translateFn) : localeKeys) as ILocaleKeys
    return <LocaleKeysContext.Provider value={value}>{children}</LocaleKeysContext.Provider>;
  };
export const useLocaleKeys = () => React.useContext(LocaleKeysContext);
