import { useLocaleKeys } from '../../../../locale-keys/LocaleKeys';
import { getErrorTextFromGiftCardError } from '../../utils/getErrorTextFromGiftCardError';
import { useApplicationContext } from './useApplicationContext/useApplicationContext';

export const useErrorSectionText = () => {
  const localeKeys = useLocaleKeys();
  const { isWalletEmpty, giftCardError } = useApplicationContext();

  const getErrorSectionText = () => {
    if (giftCardError) {
      return getErrorTextFromGiftCardError(giftCardError, localeKeys);
    }
    if (isWalletEmpty) {
      return localeKeys.checkoutSlot.redeemStoreCredit.creditEmpty.errorMessage();
    }
    return localeKeys.checkoutSlot.redeemStoreCredit.upToOrderTotal.warningMessage();
  };

  return { getErrorSectionText };
};
