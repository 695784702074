import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import App from '../components/App/App';
import AppWrapper from '../components/App/AppWrapper';
import { ApplicationProps } from '../core/hooks/useApplicationContext/useApplicationContext';

/* istanbul ignore next */
const Widget: FC<WidgetProps<ApplicationProps>> = (applicationProps) => {
  return (
    <AppWrapper Component={() => <App />} applicationProps={applicationProps} />
  );
};

export default Widget;
