import { useBi } from '@wix/yoshi-flow-editor';
import { riseWixPluginClickSrc35Evid201 } from '@wix/bi-logger-rise-ai-data/v2';
import { useApplicationContext } from './useApplicationContext/useApplicationContext';
import { defaultBiMultiplier, sitePluginId, sitePluginName } from '../../consts';

export const useClickBiEvent = () => {
  const bi = useBi();
  const { userTotalStoreCreditNumber } = useApplicationContext();

  const reportClickBiEvent = ({
    buttonName,
    state,
    errorName,
  }: {
    buttonName: string;
    state?: string;
    errorName: string;
  }) => {
    bi.report(
      riseWixPluginClickSrc35Evid201({
        appId: sitePluginId,
        appName: sitePluginName,
        buttonName,
        state,
        errorName,
        balance: userTotalStoreCreditNumber! * defaultBiMultiplier,
      }),
    );
  };
  return { reportClickBiEvent };
};
