import React, { FC, useEffect, useRef } from 'react';
import {
  StatesButton,
  SpinnerTypes,
  TextField,
  Spinner,
  ThreeDotsLoader,
  StatesButtonStates,
} from 'wix-ui-tpa/cssVars';
import { useApplicationContext } from '../../core/hooks/useApplicationContext/useApplicationContext';
import { classes } from './AmountInput.st.css';
import { ApplicationStatus, BIClickEvents } from '../../types/types';
import { useLocaleKeys } from '../../../../locale-keys/LocaleKeys';
import {
  ErrorSection,
  ErrorSectionDataHooks,
} from '../ErrorSection/ErrorSection';
import { useClickBiEvent } from '../../core/hooks/useClickBiEvent';
import { useErrorSectionText } from '../../core/hooks/useErrorSectionText';

export enum AmountInputDataHooks {
  Container = 'amount-input-container',
  TextInput = 'amount-input-text-input',
  ApplyButton = 'amount-input-button',
  TextLoader = 'amount-input-text-loader',
  ThreeDotsLoaderIcon = 'amount-input-three-dots-loader',
}

export interface AmountInputProps {}

export const AmountInput: FC<AmountInputProps> = () => {
  const { redeemableStoreCredit, updateCheckout, status, giftCardError } =
    useApplicationContext();
  const localeKeys = useLocaleKeys();
  const { reportClickBiEvent } = useClickBiEvent();
  const statesButtonRef = useRef<StatesButton>(null);
  const { getErrorSectionText } = useErrorSectionText();
  const isButtonDisabled =
    status === ApplicationStatus.UPDATING ||
    status === ApplicationStatus.Disabled;

  useEffect(() => {
    if (giftCardError) {
      statesButtonRef.current?.focus();
    }
  }, [giftCardError, statesButtonRef]);

  return (
    <div>
      <div
        className={classes.container}
        data-hook={AmountInputDataHooks.Container}
      >
        <TextField
          className={classes.textField}
          data-hook={AmountInputDataHooks.TextInput}
          id={AmountInputDataHooks.TextInput}
          value={redeemableStoreCredit}
          successIcon
          disabled={true}
          newErrorMessage
          suffix={
            status === ApplicationStatus.UPDATING ? (
              <Spinner
                data-hook={AmountInputDataHooks.TextLoader}
                type={SpinnerTypes.slim}
                diameter={16}
              />
            ) : null
          }
          error={!!giftCardError}
        />
        <StatesButton
          className={classes.applyStatesButton}
          data-hook={AmountInputDataHooks.ApplyButton}
          idleContent={localeKeys.checkoutSlot.redeemStoreCredit.cta()}
          disabled={isButtonDisabled}
          state={
            status === ApplicationStatus.UPDATING
              ? StatesButtonStates.IN_PROGRESS
              : StatesButtonStates.IDLE
          }
          onClick={() => {
            updateCheckout();
            reportClickBiEvent({
              buttonName: BIClickEvents.APPLY,
              errorName: getErrorSectionText(),
            });
          }}
          inProgressContent={
            <ThreeDotsLoader
              data-hook={AmountInputDataHooks.ThreeDotsLoaderIcon}
              className={classes.threeDotButton}
            />
          }
          aria-describedby={`${AmountInputDataHooks.TextInput} ${ErrorSectionDataHooks.ErrorText}`}
          ref={statesButtonRef}
        />
      </div>
      <ErrorSection />
    </div>
  );
};
