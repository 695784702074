import React, { FC } from 'react';
import { TextButton, TextButtonPriority, Text } from 'wix-ui-tpa/cssVars';
import { CreditCard } from '@wix/wix-ui-icons-common';
import { useApplicationContext } from '../../core/hooks/useApplicationContext/useApplicationContext';
import { classes } from './StoreCreditBalance.st.css';
import { useLocaleKeys } from '../../../../locale-keys/LocaleKeys';
import { useClickBiEvent } from '../../core/hooks/useClickBiEvent';
import { ApplicationStatus, BIClickEvents } from '../../types/types';
import { useErrorSectionText } from '../../core/hooks/useErrorSectionText';

export enum StoreCreditBalanceDataHooks {
  Container = 'store-credit-balance-container',
  BalanceTextButton = 'store-credit-balance-text-button',
  BalanceText = 'store-credit-balance-balance-text',
  Icon = 'store-credit-balance-icon',
}

export interface StoreCreditBalanceProps {
  isCollapsed: boolean;
  setIsCollapsed: () => void;
}

export const StoreCreditBalance: FC<StoreCreditBalanceProps> = ({
  isCollapsed,
  setIsCollapsed,
}) => {
  const { userTotalStoreCredit, status } = useApplicationContext();
  const localeKeys = useLocaleKeys();
  const { reportClickBiEvent } = useClickBiEvent();
  const { getErrorSectionText } = useErrorSectionText();
  const isButtonDisabled =
    status === ApplicationStatus.UPDATING ||
    status === ApplicationStatus.Disabled;

  return (
    <div data-hook={StoreCreditBalanceDataHooks.Container}>
      <TextButton
        className={classes.textButton}
        data-hook={StoreCreditBalanceDataHooks.BalanceTextButton}
        priority={TextButtonPriority.primary}
        prefixIcon={<CreditCard data-hook={StoreCreditBalanceDataHooks.Icon} />}
        onClick={() => {
          setIsCollapsed();
          reportClickBiEvent({
            buttonName: isCollapsed
              ? BIClickEvents.EXPAND
              : BIClickEvents.COLLAPSE,
            errorName: getErrorSectionText(),
            state: isButtonDisabled ? 'disabled' : '',
          });
        }}
        aria-expanded={!isCollapsed}
      >
        <Text data-hook={StoreCreditBalanceDataHooks.BalanceText}>
          {localeKeys.checkoutSlot.redeemStoreCredit.title.new({
            amount: userTotalStoreCredit,
          })}
        </Text>
      </TextButton>
    </div>
  );
};
