import React, { FC } from 'react';
import { Text, TextPriority } from 'wix-ui-tpa/cssVars';
import { Info } from '@wix/wix-ui-icons-common/on-stage';
import { classes } from './ErrorSection.st.css';
import { useApplicationContext } from '../../core/hooks/useApplicationContext/useApplicationContext';
import { useErrorSectionText } from '../../core/hooks/useErrorSectionText';

export enum ErrorSectionDataHooks {
  Container = 'error-section-container',
  ErrorText = 'error-section-text',
  Icon = 'error-section-icon',
}

export const ErrorSection: FC = () => {
  const { giftCardError } = useApplicationContext();
  const { getErrorSectionText } = useErrorSectionText();

  return (
    <div
      className={classes.container}
      data-hook={ErrorSectionDataHooks.Container}
    >
      <Info
        className={giftCardError ? classes.infoIconError : classes.infoIcon}
        data-hook={ErrorSectionDataHooks.Icon}
      />
      <Text
        className={giftCardError ? classes.errorText : undefined}
        data-hook={ErrorSectionDataHooks.ErrorText}
        id={ErrorSectionDataHooks.ErrorText}
        priority={TextPriority.secondary}
      >
        {getErrorSectionText()}
      </Text>
    </div>
  );
};
